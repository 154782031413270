import React from "react"

const Heart = () => (
  <svg className="heart" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <title>simple-icon-white-heart</title>
    <path
      d="M372.76,215.11a141.44,141.44,0,0,1-1.23,14.53,86.63,86.63,0,0,1-14.59,37.78,153.28,153.28,0,0,1-18.26,21.12c-8.63,8.46-17.76,16.38-26.87,24.28C295.7,326.76,279.6,340.73,264,355.25c-2.67,2.5-5.33,5-8.05,7.53-1.41-1.34-2.81-2.62-4.18-3.94-15.56-14.61-31.61-28.67-47.75-42.65-9.57-8.25-19.15-16.5-28.29-25.25a171.94,171.94,0,0,1-18-19.76,86.5,86.5,0,0,1-15.37-31.61,96,96,0,0,1-2.67-16.92c-.32-4.83-.59-9.67-.51-14.52.37-21.46,11.53-41.16,30.86-51.63a60.94,60.94,0,0,1,21.17-6.75,64.18,64.18,0,0,1,15.88-.07,60.53,60.53,0,0,1,23.76,8.22,73,73,0,0,1,24.33,24.28l.8,1.29a4.38,4.38,0,0,0,.45-.64,74.68,74.68,0,0,1,19.41-21.41,59.93,59.93,0,0,1,28.05-11.58c17.46-2.24,33.36,1.71,47.17,12.82,10.38,8.36,16.9,19.21,20.09,32.1A73.68,73.68,0,0,1,372.76,215.11Z"
      style={{fill: '#fff', fillRule: 'evenodd'}}
    />
  </svg>
)

export default Heart
