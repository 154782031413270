import React from "react"

const LogoLarge = () => (
  <svg
    className="logo"
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 822.48 602.84"
  >
    <title>justagirlfromoz_logo2</title>
    <path
      d="M15.3,512.38c-23.87-3.43-16.61-41.44,0-46.22,5.35-1.52,5.35,5,1,6.5-10.51,3.44-18,30.75-.38,33.23,31.51,4.58,32.27-111.35,31.89-131.78,0-8-5.15-5-5.73-1.15s-5.92,2.48-5.35-1.15c.77-5.54,5.35-10.12,10.13-9.16C66.49,366.27,56.56,518.31,15.3,512.38Z"
      transform="translate(0)"
      style={{ fill: "#fff" }}
    />
    <path
      d="M130.66,442.48c-2.1,4.78-4,9.55-6.49,14.13-6.3,10.89-13.37,17.77-20.82,17.77-8.21,0-10.69-6.11-10.88-13.37-4.78,8.21-9.94,13.37-16.24,13.37-17.38,0-7.64-33-5.16-43.74,1.72-6.49,9.94-3.82,9,1.91-1.53,8.4-5.35,18.72-5.35,25.78,0,20.06,14,0,21-17.76.38-.76.38-.38.58-1.34,1.52-6.49,10.31-3.82,9,1.91-1.72,7.07-3.44,10.13-3.44,17.19,0,15.09,9.36,7.45,17-4.77,3.06-4.78,4.59-8.6,6.88-13.75C127.8,435.41,132.77,438.09,130.66,442.48Z"
      transform="translate(0)"
      style={{ fill: "#fff" }}
    />
    <path
      d="M148.43,434.65c-.19,3.82-5.73,3.44-5.54-.19.19-3.25-2.87-6.49-5.16-3.44-4,5.35-5,25-3.82,29.41a18.25,18.25,0,0,1,4.4-.95c3.43-.38,3.43,6.11.19,6.3a9.19,9.19,0,0,0-3.63,1c2.29,9,7.06,31.7-7.83,32.47-14.33.57-14.52-26.36-.39-34.76-5-13.76-2.1-42.6,13.57-41.07C145,423.57,148.62,429.3,148.43,434.65ZM128.18,470c-7.45,5.35-9.74,23.69-1.33,22.73C134.68,491.76,129,472.09,128.18,470Z"
      transform="translate(0)"
      style={{ fill: "#fff" }}
    />
    <path
      d="M198.66,442.48c-2.29,5.16-6.3,14.71-11.08,24.26C180.52,481.25,171.92,496,165.81,496c-6.88-.19-10.12-8.4-10.7-16.81-.76-15.09,4.4-51.38,8.22-74.49-4.4-1.14-8.79-5.53-13-3.82-2.48,1.15-6.3-3.82-1.72-5.92,6.49-2.86,10.89.58,16,2.3.39-2.87,1-5.35,1.34-7.45,1.15-6.69,10.12-5.16,9,1.52-.38,2.49-1,5.93-1.53,10.13,5.16,3.05,9.36,5.73,11.65,3.44,4.39-3.82,7.83.19,5.54,3.05-5,6.69-12.23,3.82-18.34.77-3.82,24.25-9.36,61.88-9.36,71-.19,5,.58,9,2.87,9.55,3.63.77,10.7-12,17-25,4.77-9.93,9-20.05,10.88-24.25C195.8,435.41,200.76,438.09,198.66,442.48Z"
      transform="translate(0)"
      style={{ fill: "#fff" }}
    />
    <path
      d="M290,442.48c-7.07,15.85-20.63,43.55-38.59,39.15-13.37-5-4.58-25.4-2.1-34.37-8.78,13-19.67,25.59-28.84,24.63-19.86-2.67-4.39-46.22,18.72-41.06,3.06.76,8.6,4.78,8.6,6.49s-2.87-2.29-9.75-.19c-9.74,2.87-26.54,27.89-17.19,28.46,11.27,1.15,29-29.22,35.53-42.21,2.1-4.39,9.55-.76,8.4,2.87-1.33,5.34-21.58,49.08-10.88,49.46,13.94.39,25.21-22.92,31.13-35.71C286.9,435.61,291.86,438.09,290,442.48Z"
      transform="translate(0)"
      style={{ fill: "#fff" }}
    />
    <path
      d="M354.89,445C353.75,472.47,339.8,550,312.11,550.2c-8.79,0-13-12.8-10.12-23.11a20.63,20.63,0,0,1,8-11.65c5-3.44,6.49,3.63,2.1,6.88a13,13,0,0,0-5,7.45c-1.52,5.34,0,13,5,13,18.14,0,26.93-49.66,30.37-76.59.57-3.82.57-4.77.95-8.78-10.5,18.14-56.72,48.51-58.82.57-1.53-36.29,28.07-102,62.45-101.23,7.07,0,11.27,1.53,13.37,4.59,2.87,3.82-2.1,6.3-5.16,3.63-1.52-1.34-4.2-1.72-8.21-1.72-26,2.86-55.77,65.51-55.2,95.5.58,39,47.94,3.82,52.72-13.37.57-7.07,1.34-15.47,2.29-22.54,1.53-9.74,4.2-20.25,9.74-20.25,4.2-.19,5.73,4.4,5.92,9.17C362.91,420.13,358.33,438.28,354.89,445Zm2.3-34c-.2-1.15-.58-1.53-1.34-.77-2.49,2.49-1.91,21-2.29,24.45C355.66,430.07,358,416.7,357.19,411Z"
      transform="translate(0)"
      style={{ fill: "#fff" }}
    />
    <path
      d="M408.56,442.48c-.38,1.15-1.14,2.67-1.72,4.2-7.44,18.15-17,30.56-26.93,30.56-17.38,0-7.64-34.19-5-44.69,1.53-6.49,9.93-4,9,1.72-1.52,8.59-5.53,20.05-5.53,26.93,0,20.24,16.42,0,23.68-17.57a32.1,32.1,0,0,1,1.72-3.63C405.7,435.41,410.66,438.09,408.56,442.48ZM378.77,418c0-3,2.1-5.34,4.58-5.34s4.59,2.29,4.59,5.34-2.11,5.54-4.59,5.54S378.77,421.09,378.77,418Z"
      transform="translate(0)"
      style={{ fill: "#fff" }}
    />
    <path
      d="M435.11,423.38c-11.65-4-21,30.18-24.45,38.77-.38,2.3-.57,4.21-.76,5.73-.38,4.4-8.41,2.87-8.21-1.33.38-14.33,1.33-29,4.2-44.12.76-3.82,8.78-3.06,8.4.76-.19,3.25-.76,9.36-1.53,16.24,5.16-14.14,10.51-29.61,22.35-26C442.56,415.93,439.88,425.1,435.11,423.38Z"
      transform="translate(0)"
      style={{ fill: "#fff" }}
    />
    <path
      d="M484,442.48c-5.73,12.8-22.73,53.48-32.86,53.48-6.87,0-10.12-8.4-10.69-16.81-1-19.1,9.74-85.37,13-102.37,1.14-6.88,10.12-5.16,9.16,1.34-2.86,16.23-14.13,89.76-14.51,101.6,0,5,.76,9,3,9.55,6.5,1.53,23.5-39.53,27.89-49.27C481.14,435.41,486.11,438.09,484,442.48Z"
      transform="translate(0)"
      style={{ fill: "#fff" }}
    />
    <path
      d="M541.88,442.48a45,45,0,0,0-1.91,4.2c-7.26,15.47-11.84,22.16-24.07,35.15-5,5.34-9,9.74-9.36,11.84h.58c9.93,1.14,14.51,14.13,13.94,23.68-.38,6.69-3.44,12.22-9.55,12.42-9,0-13.94-9-14.52-20.63-1.14-25,9-80.22,12.8-106.77,1-7.45,10.7-5.35,9.55.58-.38,1.71-8.78,45.45-12,79.64,1.53-1.72,3.25-3.44,5-5.35,12.8-13.56,15.47-17.57,22.92-33.61a33.93,33.93,0,0,1,1.72-3.63C538.82,435.41,543.79,438.09,541.88,442.48Zm-35.72,57.11c-.57,14.13.77,24.06,5.35,23.87C517.24,523.08,519,503.22,506.16,499.59Z"
      transform="translate(0)"
      style={{ fill: "#fff" }}
    />
    <path
      d="M568.24,423.38c-11.65-4-21,30.18-24.45,38.77-.38,2.3-.57,4.21-.77,5.73-.38,4.4-8.4,2.87-8.21-1.33.38-14.33,1.34-29,4.2-44.12.77-3.82,8.79-3.06,8.41.76-.19,3.25-.77,9.36-1.53,16.24,5.16-14.14,10.5-29.61,22.35-26C575.69,415.93,573,425.1,568.24,423.38Z"
      transform="translate(0)"
      style={{ fill: "#fff" }}
    />
    <path
      d="M610.45,444l-16.43.57c-.76,9.55-6.68,30.94-15.85,35.53-18.53,8.78-22.35-40.49-.57-43.93,3.24-.57,9.74,1.14,10.31,2.67.76,1.53-3.44-2.1-8.6,3.44-7.44,8-11.84,36.86-3.43,31.9,6.3-3.06,10.31-17.58,12-32.09.38-2.48,2.1-3.82,3.63-3.63l18.91-.76C614.08,437.52,614.27,444,610.45,444Z"
      transform="translate(0)"
      style={{ fill: "#fff" }}
    />
    <path
      d="M680,441c-1,9.17-7.45,35.53-8.6,40.3-1.72,6.5-9.93,4-9-1.72,1.52-8.59,9.16-36.48,9.16-43.54,0-20.25-17.76,5.73-24.83,23.49-.57,1.15-1.33,2.86-1.9,4a86.35,86.35,0,0,1-2.3,11.08c-1.52,6.49-9.93,3.82-9-1.91,1.52-8.41,4.39-18,4.39-25,0-21.2-18.91,4.58-22,11.84a27.15,27.15,0,0,0-1.52,3.44c.76,8-5.92,12-8.22,4-3-10.7,2.3-30,4.21-37.44,1.52-6.49,9.93-3.82,9,1.91-.57,2.87-2.67,10.51-4.2,17.19,5.35-8.4,13.56-17,21.2-17s9.93,6.31,10.12,14.52C653.42,432,662,419.94,670,419.94,679.59,419.94,680.92,430.07,680,441Z"
      transform="translate(0)"
      style={{ fill: "#fff" }}
    />
    <path
      d="M783.11,417.84c0,36.86-15.86,81.56-36.87,82.51-12,.58-22-15.85-19.1-47.36C691.05,377,783.3,326.74,783.11,417.84Zm-9.36-8c-2.87-66.08-68-25.21-45.08,32.85,2.68-13.94,8.79-25.21,16.62-30.75,8.59-5.92,13,4.59,4,10.12-11.46,7.26-13.56,16.24-15.28,31.52a39.87,39.87,0,0,0,5.92,8.4c2.87,3.44-1.53,7.26-3.63,5-1.33-1.91-2.29-2.87-3.63-4.78-.19,23.3,5.73,32.28,13.37,31.52C761.91,492.52,775.47,444.2,773.75,409.82Z"
      transform="translate(0)"
      style={{ fill: "#fff" }}
    />
    <path
      d="M794.57,519.83c-8.6-.57-14.71-7.45-10.51-19.48a18.43,18.43,0,0,1,8-9.93c5.16-2.86,6.69,3.25,2.11,5.92a11.74,11.74,0,0,0-5.16,6.3c-2.29,6.88-.38,10.13,3.63,9.94,18-1.15,35.14-51,14.51-51.38-1.72,0-4.39,1.53-5.73,2.29-4.39,2.48-6.3-2.48-3.24-5.54a47.69,47.69,0,0,1,6.87-4.77c2.87-1.91,9-5.16,9.93-9.55l-21.2.38c-4,0-4.39-6.3.19-6.49l21.4-.2c10.69-.19,3.63,13.76-3.25,18.53C830.67,463.68,824,521.17,794.57,519.83Z"
      transform="translate(0)"
      style={{ fill: "#fff" }}
    />
    <path
      d="M184.5,575.76a40.33,40.33,0,0,1,6.68-.52c3.44,0,6,.8,7.56,2.24a7.25,7.25,0,0,1,2.36,5.64,7.94,7.94,0,0,1-2.08,5.76c-1.84,2-4.84,3-8.24,3a12,12,0,0,1-2.8-.24v10.8H184.5Zm3.48,13a11.67,11.67,0,0,0,2.88.28c4.2,0,6.76-2,6.76-5.76,0-3.56-2.52-5.28-6.36-5.28a14.6,14.6,0,0,0-3.28.28Z"
      transform="translate(0)"
      style={{ fill: "#fff" }}
    />
    <path
      d="M229.74,575.44v11.28h13V575.44h3.52v27h-3.52V589.76h-13V602.4h-3.48v-27Z"
      transform="translate(0)"
      style={{ fill: "#fff" }}
    />
    <path
      d="M295.85,588.64c0,9.28-5.63,14.2-12.51,14.2-7.12,0-12.12-5.52-12.12-13.68,0-8.56,5.32-14.16,12.52-14.16C291.1,575,295.85,580.64,295.85,588.64Zm-20.91.44c0,5.76,3.12,10.92,8.6,10.92s8.64-5.08,8.64-11.2c0-5.36-2.8-11-8.6-11S274.94,583.16,274.94,589.08Z"
      transform="translate(0)"
      style={{ fill: "#fff" }}
    />
    <path d="M325,578.4h-8.2v-3h20v3H328.5v24H325Z" transform="translate(0)" style={{fill: '#fff'}} />
    <path
      d="M382.17,588.64c0,9.28-5.63,14.2-12.51,14.2-7.12,0-12.12-5.52-12.12-13.68,0-8.56,5.32-14.16,12.52-14.16C377.42,575,382.17,580.64,382.17,588.64Zm-20.91.44c0,5.76,3.12,10.92,8.6,10.92s8.64-5.08,8.64-11.2c0-5.36-2.8-11-8.6-11S361.26,583.16,361.26,589.08Z"
      transform="translate(0)"
      style={{ fill: "#fff" }}
    />
    <path
      d="M427.69,601.2a25,25,0,0,1-8.27,1.48c-4.08,0-7.44-1-10.08-3.56a13.87,13.87,0,0,1-3.76-10c0-8,5.56-13.92,14.6-13.92a16.67,16.67,0,0,1,6.72,1.24l-.84,2.84a14,14,0,0,0-6-1.16c-6.56,0-10.84,4.08-10.84,10.84s4.12,10.88,10.4,10.88a11.35,11.35,0,0,0,4.64-.72v-8h-5.48v-2.8h8.87Z"
      transform="translate(0)"
      style={{ fill: "#fff" }}
    />
    <path
      d="M453.5,575.8a36.25,36.25,0,0,1,6.68-.56c3.72,0,6.12.68,7.8,2.2a6.71,6.71,0,0,1,2.12,5.12,7.08,7.08,0,0,1-5.08,6.88v.12c2.08.72,3.32,2.64,4,5.44a42,42,0,0,0,2.08,7.4h-3.6a34.93,34.93,0,0,1-1.8-6.44c-.8-3.72-2.24-5.12-5.4-5.24H457V602.4H453.5ZM457,588.08h3.56c3.72,0,6.08-2,6.08-5.12,0-3.48-2.52-5-6.2-5a14.32,14.32,0,0,0-3.44.32Z"
      transform="translate(0)"
      style={{ fill: "#fff" }}
    />
    <path
      d="M500.06,593.92l-2.8,8.48h-3.6l9.16-27H507l9.19,27H512.5l-2.88-8.48Zm8.84-2.72-2.64-7.76c-.6-1.76-1-3.36-1.4-4.92h-.08c-.4,1.6-.84,3.24-1.36,4.88l-2.64,7.8Z"
      transform="translate(0)"
      style={{ fill: "#fff" }}
    />
    <path
      d="M540.66,575.76a40.33,40.33,0,0,1,6.68-.52c3.44,0,6,.8,7.56,2.24a7.28,7.28,0,0,1,2.35,5.64,7.94,7.94,0,0,1-2.08,5.76c-1.83,2-4.83,3-8.23,3a12,12,0,0,1-2.8-.24v10.8h-3.48Zm3.48,13A11.67,11.67,0,0,0,547,589c4.2,0,6.75-2,6.75-5.76,0-3.56-2.51-5.28-6.35-5.28a14.6,14.6,0,0,0-3.28.28Z"
      transform="translate(0)"
      style={{ fill: "#fff" }}
    />
    <path
      d="M585.9,575.44v11.28h13V575.44h3.51v27h-3.51V589.76h-13V602.4h-3.48v-27Z"
      transform="translate(0)"
      style={{ fill: "#fff" }}
    />
    <path
      d="M634.7,602.4V591l-8.52-15.52h4l3.8,7.44c1,2,1.84,3.68,2.68,5.56h.08c.76-1.76,1.68-3.52,2.72-5.56l3.88-7.44h4l-9,15.48V602.4Z"
      transform="translate(0)"
      style={{ fill: "#fff" }}
    />
    <g id="layer1">
      <path
        id="path4453"
        d="M519.27,179.22l13.81-.3c4.78,0,3.54.19,3.54-4.46V30.81c-2.1-7.93-8.57-12.45-15.19-12.45l-58.26.08c-5.3.24-5.59-.46-7.76-4.22-1.56-2.71-2.84-3.88-4.61-7-1.9-3.4-1.19-3-5.17-3l-45.89.26c-4.14-.09-3.36-.56-5.32,2.83l-4.66,8.06c-2.13,4.1-1.95,2.94-6.61,2.94l-58.36-.27c-6,0-11.16,6.36-11.16,12.09l-.77,141.44c0,4.66-.91,3.8,3.36,4.95l67.18,18.56c5.8,1.6,4.11,2,7.06-3.08l6.13-21.69c2.56-4.69,1.62-4,6.72-4h28.18c6.12.14,5.82-1.62,4.3,4.08,0,0-3.18,7.41-3,11.31a10.09,10.09,0,0,0,2.09,6c2.2,2.67,5.8,3.87,9,5.18a36.2,36.2,0,0,0,9.16,2.35c3.85.48,8,1.35,11.66,0a10.23,10.23,0,0,0,4.89-4.19c5.78-9.16,8.42-31.39,8.42-31.39,1.51-5.49.53-5.36,3.29-.59l36,62.3c1.2,2.83,3.14,4.76,5.26,6.88,0,0,15.24,12.83,20.9,20.9a23.16,23.16,0,0,1,3.77,8.08c1.37,6.14,0,18.88,0,18.88.48,3.19-.47,4.87-2,7.52l-25.1,43.47c-2.14,4.21-2,3.71-6.37,4.88l-28.32,7.58c-6.7,1.9-4.64,2-9.53-2.91,0,0-24.29-32-42-42.5a21.7,21.7,0,0,0-9.64-2.6c-16.52.27-48.16,12.87-48.16,12.87-3,.42-5.28,1.83-7.82,3.3l-33,19c-5.38,3.81-4.77,1.6-6.25-3.95l-3.67-13.69c-.44-3-2-5.4-3.52-8,0,0-20.76-25.82-23.94-41.46-.61-3-.53-6.6,1.14-9.21,6.6-10.34,31.23-19.44,31.23-19.44,3.24-1.26,4.2-3.33,5.86-6.2l12-22.75"
        transform="translate(0)"
        style={{
          fill: "none",
          stroke: "#fff",
          strokeLinecap: "round",
          strokeMiterlimit: 11.3385820388794,
          strokeWidth: "8.50393652915955px",
        }}
      />
      <circle
        id="path4455"
        cx="422.63"
        cy="94.53"
        r="38.97"
        style={{
          fill: "none",
          stroke: "#fff",
          strokeLinecap: "round",
          strokeMiterlimit: 11.3385820388794,
          strokeWidth: "9.36844953228473px",
        }}
      />
      <circle
        id="path8168"
        cx="505.38"
        cy="48.11"
        r="4.04"
        style={{
          fill: "none",
          stroke: "#fff",
          strokeLinecap: "round",
          strokeMiterlimit: 11.3385820388794,
          strokeWidth: "14.6409439495699px",
        }}
      />
      <path
        id="path8170"
        d="M575.13,263.25H782"
        transform="translate(0)"
        style={{
          fill: "none",
          stroke: "#fff",
          strokeMiterlimit: 11.3385820388794,
          strokeWidth: "5.6692910194397px",
        }}
      />
      <path
        id="path8172"
        d="M67.23,267.28H274.11"
        transform="translate(0)"
        style={{
          fill: "none",
          stroke: "#fff",
          strokeMiterlimit: 11.3385820388794,
          strokeWidth: "5.6692910194397px",
        }}
      />
      <g id="g8245">
        <path
          id="rect8182"
          d="M601.73,214.88a18.84,18.84,0,0,1,10.61-3.26c30.33-.3,75.27-.91,75.27-.91-32.84,4.35-47.93,7.84-71,15a38.37,38.37,0,0,0-8.08,3.35,26.7,26.7,0,0,0-6.41,5c-2.79,3.05-6.72,10.43-6.72,10.43s-1.08-15.61,1.48-22.77a14.37,14.37,0,0,1,4.89-6.82Z"
          transform="translate(0)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.761217802855043px",
          }}
        />
        <path
          id="path8185"
          d="M636.25,189.92c-4.33,11-9.23,19.45-10.28,19s1.06-9.91,5.38-21,16.4-38.05,17.45-37.64-8.23,28.51-12.55,39.56Z"
          transform="translate(0)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.761217802855043px",
          }}
        />
        <path
          id="path8188"
          d="M641.76,200.61c-6,5.61-11.66,9.49-12.25,9s3.47-5.75,9.47-11.37,21.69-19,22.28-18.47-13.5,15.24-19.5,20.86Z"
          transform="translate(0)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.527175776023654px",
          }}
        />
        <path
          id="path8190"
          d="M623.94,149.82c-1.12.08-.2,29.66.73,41.48.18,2.36.45,4.53.72,6.65,0-.09,0-.15.07-.23.79-2.45,1.73-5.12,2.81-7.88.3-.77.86-2.05,1.23-3-1.29-13.26-4.54-37.14-5.56-37.06Z"
          transform="translate(0)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.761217802855043px",
          }}
        />
        <path
          id="path8215"
          d="M630.79,184c1.06-2.67.3-1,1.89-4.67.79-1.82,1.64-3.69,2.48-5.58,1.07-11.56,4-28.14,3.28-28.24-.84-.12-6,19.68-8.3,32.78C630.77,182.59,630.42,180.74,630.79,184Z"
          transform="translate(0)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.761217802855043px",
          }}
        />
        <path
          id="path8223"
          d="M578.82,188.87c-.6.51,13.5,15.24,19.5,20.86,1.16,1.08,2.26,2,3.36,3a18.6,18.6,0,0,1,2.42-.93c.38-.11.78-.17,1.17-.26-1.24-1.31-2.64-2.72-4.17-4.15-6-5.62-21.69-19-22.28-18.48Z"
          transform="translate(0)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.527175776023654px",
          }}
        />
        <path
          id="path8230"
          d="M610.62,187.92c2.26,11.64,2.62,21.37,1.51,21.58s-4.41-8.93-6.67-20.58S598.94,148,600,147.79s8.31,28.49,10.58,40.13Z"
          transform="translate(0)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.761217802855043px",
          }}
        />
        <path
          id="path8232"
          d="M578.75,160.64c-.9.68,15.71,25.16,22.83,34.65,1.42,1.9,2.81,3.58,4.17,5.22,0-.08,0-.14-.07-.22-.64-2.49-1.28-5.25-1.84-8.16-.16-.81-.37-2.19-.55-3.16-8.19-10.51-23.72-28.94-24.54-28.33Z"
          transform="translate(0)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.761217802855043px",
          }}
        />
        <path
          id="path8234"
          d="M602.86,185.87c-.53-2.83-.27-1-.91-5-.31-1.95-.59-4-.89-6-5.28-10.34-11.71-25.9-12.35-25.61s5.48,19.82,10.54,32.13c2.82,3.26,1.53,1.89,3.61,4.47Z"
          transform="translate(0)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.761217802855043px",
          }}
        />
      </g>
      <g id="g8265">
        <path
          id="path8247"
          d="M247.44,211.87a18.87,18.87,0,0,0-10.62-3.26c-30.32-.31-75.26-.91-75.26-.91,32.84,4.35,47.92,7.84,71,15a39,39,0,0,1,8.07,3.34,27.27,27.27,0,0,1,6.42,5c2.79,3,6.72,10.44,6.72,10.44s1.08-15.61-1.48-22.78a14.46,14.46,0,0,0-4.89-6.81Z"
          transform="translate(0)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.761217802855043px",
          }}
        />
        <path
          id="path8249"
          d="M212.92,186.9c4.32,11,9.23,19.46,10.27,19s-1.05-9.92-5.37-21-16.41-38.06-17.45-37.65,8.23,28.52,12.55,39.56Z"
          transform="translate(0)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.761217802855043px",
          }}
        />
        <path
          id="path8251"
          d="M207.41,197.59c6,5.62,11.66,9.49,12.25,9s-3.47-5.74-9.47-11.36-21.69-19-22.28-18.47,13.5,15.23,19.5,20.85Z"
          transform="translate(0)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.527175776023654px",
          }}
        />
        <path
          id="path8253"
          d="M225.23,146.8c1.12.09.2,29.67-.73,41.49-.18,2.36-.45,4.53-.72,6.64,0-.08,0-.14-.07-.22-.79-2.45-1.73-5.12-2.81-7.88-.3-.77-.86-2.05-1.23-3,1.29-13.26,4.54-37.15,5.56-37.07Z"
          transform="translate(0)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.761217802855043px",
          }}
        />
        <path
          id="path8255"
          d="M218.37,181c-1.06-2.67-.29-1-1.89-4.68-.78-1.81-1.64-3.69-2.47-5.57-1.07-11.56-4-28.14-3.28-28.24s6,19.68,8.3,32.78C218.39,179.58,218.75,177.73,218.37,181Z"
          transform="translate(0)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.761217802855043px",
          }}
        />
        <path
          id="path8257"
          d="M270.35,185.86c.59.51-13.51,15.24-19.5,20.86-1.16,1.08-2.26,2-3.36,3a18.6,18.6,0,0,0-2.42-.93c-.38-.11-.78-.17-1.17-.26,1.24-1.31,2.63-2.72,4.17-4.16,6-5.62,21.69-19,22.28-18.47Z"
          transform="translate(0)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.527175776023654px",
          }}
        />
        <path
          id="path8259"
          d="M238.55,184.91c-2.26,11.64-2.62,21.37-1.52,21.58s4.42-8.94,6.68-20.58,6.52-40.92,5.42-41.14-8.32,28.49-10.58,40.14Z"
          transform="translate(0)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.761217802855043px",
          }}
        />
        <path
          id="path8261"
          d="M270.42,157.63c.89.67-15.71,25.16-22.83,34.65-1.42,1.89-2.81,3.58-4.17,5.22,0-.08,0-.14.07-.23.64-2.49,1.27-5.24,1.84-8.15.16-.82.37-2.19.55-3.16,8.18-10.51,23.72-29,24.54-28.33Z"
          transform="translate(0)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.761217802855043px",
          }}
        />
        <path
          id="path8263"
          d="M246.31,182.85c.53-2.82.27-1,.9-4.95.31-2,.59-4,.9-6,5.28-10.35,11.71-25.9,12.35-25.61s-5.49,19.82-10.54,32.13c-2.82,3.26-1.53,1.89-3.61,4.46Z"
          transform="translate(0)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.761217802855043px",
          }}
        />
      </g>
    </g>
  </svg>
)

export default LogoLarge
