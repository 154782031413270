import React from "react"

const LogoSmall = () => (
  <svg
    className="logo"
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 526.15 345.03"
  >
    <title>logo_small</title>
    <g id="layer1">
      <path
        id="path4453"
        d="M452,179.22l13.81-.3c4.78,0,3.54.19,3.54-4.46V30.81c-2.1-7.93-8.57-12.45-15.18-12.45l-58.27.08c-5.3.24-5.59-.46-7.75-4.22-1.57-2.71-2.85-3.88-4.62-7-1.9-3.4-1.18-3-5.17-3l-45.89.26c-4.14-.09-3.36-.56-5.32,2.83l-4.66,8.06c-2.13,4.1-1.95,2.94-6.61,2.94l-58.36-.27c-6,0-11.16,6.36-11.16,12.09l-.77,141.44c0,4.66-.9,3.8,3.36,4.95l67.18,18.56c5.8,1.6,4.12,2,7.06-3.08l6.13-21.69c2.56-4.69,1.62-4,6.72-4h28.18c6.13.14,5.83-1.62,4.3,4.08,0,0-3.17,7.41-3,11.31a10.09,10.09,0,0,0,2.09,6c2.2,2.67,5.8,3.87,9,5.18a36.2,36.2,0,0,0,9.16,2.35c3.85.48,8,1.35,11.66,0a10.3,10.3,0,0,0,4.9-4.19c5.77-9.16,8.41-31.39,8.41-31.39,1.51-5.49.54-5.36,3.29-.59l36,62.3c1.2,2.83,3.14,4.76,5.26,6.88,0,0,15.25,12.83,20.9,20.9a23,23,0,0,1,3.77,8.08c1.37,6.14,0,18.88,0,18.88.49,3.19-.47,4.87-2,7.52l-25.1,43.47c-2.14,4.21-2,3.71-6.36,4.88l-28.33,7.58c-6.69,1.9-4.63,2-9.53-2.91,0,0-24.29-32-42-42.5a21.69,21.69,0,0,0-9.63-2.6c-16.53.27-48.17,12.87-48.17,12.87-3,.42-5.28,1.83-7.82,3.3l-33,19c-5.38,3.81-4.77,1.6-6.25-3.95l-3.67-13.69c-.44-3-2-5.4-3.52-8,0,0-20.76-25.82-23.93-41.46-.62-3-.53-6.6,1.13-9.21C238.36,240.12,263,231,263,231c3.25-1.26,4.2-3.33,5.86-6.2l12-22.75"
        transform="translate(-94.28)"
        style={{
          fill: "none",
          stroke: "#fff",
          strokeLinecap: "round",
          strokeMiterlimit: 11.338582038879,
          strokeWidth: "8.50393652915955px",
        }}
      />
      <circle
        id="path4455"
        cx="261.12"
        cy="94.53"
        r="38.97"
        style={{
          fill: "none",
          stroke: "#fff",
          strokeLinecap: "round",
          strokeMiterlimit: 11.338582038879,
          strokeWidth: "9.36844953228473px",
        }}
      />
      <circle
        id="path8168"
        cx="343.86"
        cy="48.11"
        r="4.04"
        style={{
          fill: "none",
          stroke: "#fff",
          strokeLinecap: "round",
          strokeMiterlimit: 11.338582038879,
          strokeWidth: "14.6409439495699px",
        }}
      />
      <g id="g8245">
        <path
          id="rect8182"
          d="M534.5,214.88a18.87,18.87,0,0,1,10.62-3.26c30.32-.3,75.26-.91,75.26-.91-32.84,4.35-47.92,7.84-71,15a38.51,38.51,0,0,0-8.07,3.35,26.75,26.75,0,0,0-6.42,5c-2.79,3.05-6.72,10.43-6.72,10.43s-1.08-15.61,1.48-22.77a14.44,14.44,0,0,1,4.89-6.82Z"
          transform="translate(-94.28)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.761217802855043px",
          }}
        />
        <path
          id="path8185"
          d="M569,189.92c-4.32,11-9.23,19.45-10.28,19s1.06-9.91,5.38-21,16.41-38.05,17.45-37.64-8.23,28.51-12.55,39.56Z"
          transform="translate(-94.28)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.761217802855043px",
          }}
        />
        <path
          id="path8188"
          d="M574.53,200.61c-6,5.61-11.66,9.49-12.25,9s3.47-5.75,9.47-11.37,21.69-19,22.28-18.47-13.5,15.24-19.5,20.86Z"
          transform="translate(-94.28)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.527175776023654px",
          }}
        />
        <path
          id="path8190"
          d="M556.71,149.82c-1.12.08-.2,29.66.73,41.48.18,2.36.45,4.53.72,6.65,0-.09,0-.15.07-.23.79-2.45,1.73-5.12,2.81-7.88.3-.77.86-2.05,1.23-3-1.29-13.26-4.54-37.14-5.56-37.06Z"
          transform="translate(-94.28)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.761217802855043px",
          }}
        />
        <path
          id="path8215"
          d="M563.57,184c1.06-2.67.29-1,1.89-4.67.78-1.82,1.64-3.69,2.47-5.58,1.07-11.56,4-28.14,3.28-28.24s-6,19.68-8.3,32.78C563.55,182.59,563.19,180.74,563.57,184Z"
          transform="translate(-94.28)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.761217802855043px",
          }}
        />
        <path
          id="path8223"
          d="M511.59,188.87c-.59.51,13.51,15.24,19.5,20.86,1.16,1.08,2.26,2,3.36,3a18.6,18.6,0,0,1,2.42-.93c.38-.11.78-.17,1.17-.26-1.24-1.31-2.63-2.72-4.17-4.15-6-5.62-21.69-19-22.28-18.48Z"
          transform="translate(-94.28)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.527175776023654px",
          }}
        />
        <path
          id="path8230"
          d="M543.39,187.92c2.26,11.64,2.62,21.37,1.52,21.58s-4.42-8.93-6.68-20.58-6.52-40.92-5.42-41.13,8.32,28.49,10.58,40.13Z"
          transform="translate(-94.28)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.761217802855043px",
          }}
        />
        <path
          id="path8232"
          d="M511.52,160.64c-.9.68,15.71,25.16,22.83,34.65,1.42,1.9,2.81,3.58,4.17,5.22a1.87,1.87,0,0,0-.07-.22c-.64-2.49-1.27-5.25-1.84-8.16-.16-.81-.37-2.19-.55-3.16-8.18-10.51-23.72-28.94-24.54-28.33Z"
          transform="translate(-94.28)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.761217802855043px",
          }}
        />
        <path
          id="path8234"
          d="M535.63,185.87c-.53-2.83-.27-1-.9-5-.32-1.95-.6-4-.9-6-5.28-10.34-11.71-25.9-12.35-25.61S527,169.09,532,181.4c2.82,3.26,1.53,1.89,3.61,4.47Z"
          transform="translate(-94.28)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.761217802855043px",
          }}
        />
      </g>
      <g id="g8265">
        <path
          id="path8247"
          d="M180.21,211.87a18.85,18.85,0,0,0-10.62-3.26c-30.32-.31-75.26-.91-75.26-.91,32.84,4.35,47.93,7.84,71,15a39.09,39.09,0,0,1,8.08,3.34,27.42,27.42,0,0,1,6.41,5c2.79,3,6.72,10.44,6.72,10.44s1.08-15.61-1.48-22.78a14.4,14.4,0,0,0-4.89-6.81Z"
          transform="translate(-94.28)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.761217802855043px",
          }}
        />
        <path
          id="path8249"
          d="M145.69,186.9c4.32,11,9.23,19.46,10.28,19s-1.06-9.92-5.38-21-16.4-38.06-17.45-37.65,8.23,28.52,12.55,39.56Z"
          transform="translate(-94.28)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.761217802855043px",
          }}
        />
        <path
          id="path8251"
          d="M140.18,197.59c6,5.62,11.66,9.49,12.25,9S149,200.83,143,195.21s-21.69-19-22.28-18.47,13.5,15.23,19.5,20.85Z"
          transform="translate(-94.28)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.527175776023654px",
          }}
        />
        <path
          id="path8253"
          d="M158,146.8c1.12.09.2,29.67-.73,41.49-.18,2.36-.45,4.53-.72,6.64,0-.08,0-.14-.07-.22-.79-2.45-1.73-5.12-2.81-7.88-.3-.77-.86-2.05-1.23-3,1.29-13.26,4.54-37.15,5.56-37.07Z"
          transform="translate(-94.28)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.761217802855043px",
          }}
        />
        <path
          id="path8255"
          d="M151.15,181c-1.06-2.67-.3-1-1.89-4.68-.79-1.81-1.64-3.69-2.48-5.57-1.07-11.56-4-28.14-3.28-28.24.84-.12,6,19.68,8.3,32.78C151.17,179.58,151.52,177.73,151.15,181Z"
          transform="translate(-94.28)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.761217802855043px",
          }}
        />
        <path
          id="path8257"
          d="M203.12,185.86c.6.51-13.5,15.24-19.5,20.86-1.16,1.08-2.26,2-3.36,3a18.6,18.6,0,0,0-2.42-.93c-.38-.11-.78-.17-1.17-.26,1.24-1.31,2.64-2.72,4.17-4.16,6-5.62,21.69-19,22.28-18.47Z"
          transform="translate(-94.28)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.527175776023654px",
          }}
        />
        <path
          id="path8259"
          d="M171.32,184.91c-2.26,11.64-2.62,21.37-1.52,21.58s4.42-8.94,6.68-20.58S183,145,181.9,144.77s-8.32,28.49-10.58,40.14Z"
          transform="translate(-94.28)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.761217802855043px",
          }}
        />
        <path
          id="path8261"
          d="M203.19,157.63c.9.67-15.71,25.16-22.83,34.65-1.42,1.89-2.81,3.58-4.17,5.22,0-.08.05-.14.07-.23.64-2.49,1.28-5.24,1.84-8.15.16-.82.37-2.19.55-3.16,8.18-10.51,23.72-29,24.54-28.33Z"
          transform="translate(-94.28)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.761217802855043px",
          }}
        />
        <path
          id="path8263"
          d="M179.08,182.85c.53-2.82.27-1,.91-4.95.31-2,.59-4,.89-6,5.28-10.35,11.71-25.9,12.35-25.61s-5.48,19.82-10.54,32.13c-2.82,3.26-1.53,1.89-3.61,4.46Z"
          transform="translate(-94.28)"
          style={{
            fill: "#fff",
            stroke: "#fff",
            strokeLinecap: "round",
            strokeMiterlimit: 11.3385820388794,
            strokeWidth: "0.761217802855043px",
          }}
        />
      </g>
    </g>
  </svg>
)

export default LogoSmall
